.message-bar {
  background-color: #ffeb3b;
  color: #333;
  padding: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.content-container {
  padding-top: 50px; /* Adjust this value based on the height of your message bar */
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  height: 100%;
  border: none;
}